function Separator(props) {
    let separator = props.separator
    let icon = props.icon

    return (
      <div className="h-auto w-full">
        <img className="w-full" src={separator} />
      </div>
    );
}

export default Separator;