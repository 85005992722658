import React, { useEffect, useState } from "react";

function CategoryIcon(props) {
    const [image, setImage] = useState(props.image);
    const size = props.size;
    const target = props.target;
    let url = props.url;
    let [imageOpacityValue, setImageOpacityValue] = useState("opacity-100");
    let [hoverOpacityValue, setHoverOpacityValue] = useState("opacity-0");

    let width = "w-1/3 md:w-1/6 scale-110";
    if (size == "large") {
        width = "w-full md:w-1/2 lg:w-1/2"
    }

    let hover = image;
    if (props.hover !== undefined) {
        hover = props.hover;
    }

    const [hashValue, setHashValue] = useState('');
  
    useEffect(() => {
        // Function to update the hash value
        const updateHashValue = () => {
        setHashValue(window.location.hash.slice(1)); // Remove the '#' character
        };
        
        // Get the initial hash value
        if (window.location.hash.slice(1) == "") {
            setHashValue("about-us")
        } else {
            updateHashValue();
        }

        if ("#"+hashValue == url) {
            setImageOpacityValue("opacity-0");
            setHoverOpacityValue("opacity-100");
        } else {
            setImageOpacityValue("opacity-100");
            setHoverOpacityValue("opacity-0");
        }

        // Listen for hash changes
        window.addEventListener('hashchange', updateHashValue);
    })

    return (
        <div className={`group relative ${width}`}>
            <a href={url} className={width} target={target}>
                <img className={`m-auto w-full h-auto ${imageOpacityValue} hover:-rotate-12 group-hover:opacity-0`} src={image} />
                <img className={`absolute m-auto w-full h-auto top-0 left-0 ${hoverOpacityValue} rotate-12 group-hover:opacity-100`} src={hover} />
            </a>
        </div>
    );
}

export default CategoryIcon;