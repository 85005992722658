import NavigatorItem from "./NavigatorItem.js";

function Navigator(props) {
  let list = props.list
  let size = props.size
  let display = "flex"

  if (size === "large") {
    display = "hidden lg:flex"
  }

  return (
    <div className={`h-auto w-full ${display} flex-row flex-wrap flex-shrink justify-between`}>
      {list.map((item) => (
        <NavigatorItem 
          image={item.image}
          size={size}
          url={item.url}
          hover={item.hover}
          target={item.target}
        />
      ))}
    </div>
  );
}

export default Navigator;