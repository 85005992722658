import React, { useState, useEffect } from "react";

function Header(props) {
    const logo = props.logo
    const hamburgerLogo = props.hamburgerLogo
    const closeLogo = props.closeLogo
    const list = props.navigatorMenu
    const [navState, setNavState] = useState("hidden")


    return (
        <div className="relative flex h-24 lg:h-auto w-full lg:px-0 gap-2">
            <div className="p-4 h-full m-auto w-4/5 lg:w-2/5">
                <img className="w-full h-auto object-contain" src={logo} />
            </div>
            <div 
                className="cursor-pointer flex h-full w-auto aspect-square bg-blue-500 lg:hidden"
                onClick={() => setNavState("")}
            >
                <img className="m-auto h-8 w-8 justify-center" src={hamburgerLogo} />
            </div>
            <div className={`absolute z-50 w-full pt-8 cursor-pointer h-auto bg-blue-500 ${navState}`}>
                <div 
                    className="w-full h-8 mb-8" 
                    onClick={() => setNavState("hidden")}
                >
                    <img className="m-auto h-16 w-16" src={closeLogo} />
                </div>
                <div className="w-2/3 h-auto m-auto">
                    {list.map((item) => (
                        <div className="w-full h-auto flex flex-col">
                            <a href={item.url} target="_blank">
                                <img src={item.image} />
                            </a>
                        </div>
                    ))}
                </div> 
            </div>
        </div>
    );
}

export default Header;