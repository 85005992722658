import React, { useState, useEffect } from "react";

function CardItem(props) {
    const id = props.id;
    const list = props.childs;
    let display = "hidden"

    const [hashValue, setHashValue] = useState('');
  
    useEffect(() => {
        // Function to update the hash value
        const updateHashValue = () => {
        setHashValue(window.location.hash.slice(1)); // Remove the '#' character
        };
        
        // Get the initial hash value
        if (window.location.hash.slice(1) == "") {
            setHashValue("about-us")
        } else {
            updateHashValue();
        }

        // Listen for hash changes
        window.addEventListener('hashchange', updateHashValue);
    })

    if (hashValue === id) {
        display = "grid"
    }

    let grid = 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3';

    if (list.length == 1) {
        grid = 'grid-cols-1';
    }

    return (
        <div id={id} className={`${display} ${grid} gap-4`}>
            {list.map((item) => (
                <div className="p-3 bg-blue-200 bg-opacity-50 rounded-lg w-full">
                    <img src={item.image} />
                    <div className="flex flex-col justify-between mt-2">
                        <p className="text-orange-700 font-bold">{item.title}</p>
                        <p className="">{item.subtitle}</p>
                    </div>
                    <p className="" dangerouslySetInnerHTML={{ __html: item.description }}>
                    </p>
                </div>
            ))}
        </div>
    );
}

export default CardItem;