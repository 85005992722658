import React, { useEffect, useRef } from "react";

function Audio(props) {
    const backsound = props.backsound;
    const audioRef = useRef(null);

    // Function to set a cookie
    const setCookie = (name, value, days) => {
        let expires = '';
        if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
        }
        document.cookie = `${name}=${value || ''}${expires}; path=/`;
    };

    // Function to get a cookie
    const getCookie = (name) => {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    };

    useEffect(() => {
        const hasPlayed = getCookie('audioPlayed');

        if (hasPlayed) {
        audioRef.current.play().catch((error) => {
            console.log('Auto-play was prevented:', error);
        });
        } else {
        const audioElement = audioRef.current;
        document.addEventListener('play', () => {
            audioElement.play();
            setCookie('audioPlayed', 'true', 30); // Store for 30 days
        });
        }

        // Cleanup listener
        return () => {
        const audioElement = audioRef.current;
        audioElement.removeEventListener('play', () => {});
        };
    }, []);

    document.addEventListener('click', () => {
        document.getElementById("audio").play();
    });

    return (
        <audio id="audio" ref={audioRef} loop>
        <source src={backsound} type="audio/mpeg" />
        Your browser does not support the audio element.
        </audio>
    );
}

export default Audio;