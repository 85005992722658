// Import Swiper React components
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default (props) => {
  let list = props.list
  let slidesPerView = props.slidesPerView;
  
  return (
    <div className="h-auto w-full z-0 py-4 bg-blue-200 bg-opacity-50">
      <Swiper
        modules={[Autoplay, Navigation, Scrollbar, Pagination, A11y]}
        spaceBetween={16}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        slidesPerView={slidesPerView}
        pagination={{ clickable: true }}
      >
        {list.map((item) => (
          <SwiperSlide>
              <a href={item.url} target="_blank">
                <img src={item.image} />
              </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};