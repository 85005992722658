import React, { useEffect, useState } from "react";

import CardItem from "./CardItem.js";

function Cards(props) {
    const list = props.list

    return (
        <div className="h-auto w-full grid gap-4">
            {Object.keys(list).map((key) => (
            <CardItem 
                id={key}
                childs={list[key]}
            />
            ))}
        </div>
    );
}

export default Cards;